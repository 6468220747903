<template>
  <div style="width:100%">
    <v-row no-gutters>
      <v-col cols="12" md="6"><b>{{ (item.listado.descripcion)? item.listado.descripcion : null }}</b></v-col>
      <v-col cols="12" md="5">
        <v-icon size="18" :color="(item.estado_envio === 1)?'green':'red'">mdi-email</v-icon> {{ item.correo }}
      </v-col>
      <v-col cols="12" md="1">
        <v-icon v-if="item.estado_envio === 1" size="18" color="green">mdi-check-bold</v-icon>
        <v-icon v-else size="18" color="red">mdi-close-octagon</v-icon>
      </v-col>
    </v-row>
    <br>
    <v-row no-gutters v-if="item.codigoCorreo > 0">
      <v-col cols="12" class="text-left">
        <v-btn :color="'green'" @click="downloadEmail(item.codigoCorreo)" icon><v-icon>mdi-email</v-icon></v-btn>
        <!-- <v-btn @click="verCorreo">VER CORREO</v-btn> -->
      </v-col>
    </v-row>
  </div>

</template>

<script>
// import dialgoVerCorreo from '@/components/correos/dialogo_verComponent.vue'
import recFunciones from '@/js/rec_funciones.js'
import recUpload from '@/js/rec_upload.js'
import recCorreos from '@/js/rec_correos.js'
export default {
  name: 'logsCierreData',
  mixins: [recFunciones, recUpload, recCorreos],
  props: ['colores', 'item'],
  data: () => ({
    dialogoCorreo: false,
    tokenCorreo: 0,
    id_proyecto: 0,
    id_correo: 0
  }),
  created () {

  },
  methods: {
    downloadEmail (codigo) {
      this.rec_correos_download(codigo)
    }
  }

}
</script>
<style scoped>
.parpadea {
  animation-name: parpadeo;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  -webkit-animation-name:parpadeo;
  -webkit-animation-duration: 1s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
}
@-moz-keyframes parpadeo{
  0% { opacity: 1.0; }
  50% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

@-webkit-keyframes parpadeo {
  0% { opacity: 1.0; }
  50% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

@keyframes parpadeo {
  0% { opacity: 1.0; }
  50% { opacity: 0.0; }
  100% { opacity: 1.0; }
}
</style>
