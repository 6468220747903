import axios from 'axios'
export default {
  methods: {
    rec_correos_ver (pId) {
      this.items_archivos = []
      // this.srcUrl = 'https://testgm.mysoul.software:3000/webtopfactory/fileshtmls/' + pId + '.html'
      this.srcUrl = 'https://gm-ms.mysoul.software/webtopfactory/fileshtmls/' + pId + '.html'
      this.loandingQR = true
      // const urlroute = 'http://webtopfactory.outsourcingcos.com/laravel8/api_gmf/public/api/ver_correo/' + pId
      const urlroute = 'https://gm-ms.mysoul.software/webtopfactory/api/ver_correo/' + pId
      this.registro = null
      axios({
        url: urlroute,
        method: 'GET',
        data: null
        /*
        auth: {
          username: this.$store.getters.getHeadUsuario,
          password: this.$store.getters.getHeadPassword
        }
        */
      }).then(response => {
        this.items_archivos = response.data
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.loandingQR = false
      })
    },
    rec_correos_srcImagen (pId, pFile) {
      return 'https://gm-ms.mysoul.software/webtopfactory/filesadjuntos/' + pId + '/' + pFile
    },
    rec_correos_download (pId) {
      window.location.href = 'https://gm-ms.mysoul.software/webtopfactory/filesmsg/' + pId + '.msg'
      // window.open('http://webtopfactory.outsourcingcos.com/laravel8/api_gmf/public/filesmsg/' + pId + '.msg', '_blank')
    },
    rec_correos_downloadFile (pId, pFile) {
      // window.location.href = 'http://webtopfactory.outsourcingcos.com/laravel8/api_gmf/public/filesadjuntos/' + pId + '/' + pFile
      window.open('https://gm-ms.mysoul.software/webtopfactory/filesadjuntos/' + pId + '/' + pFile, '_blank')
    },
    rec_correos_por_id (pId) {
      this.loandingQR = true
      const urlroute = this.$store.getters.getUrl + 'gestion_id/' + pId
      this.registro = null
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        }
      }).then(response => {
        this.dataReplica = []
        this.registro = response.data
        this.estadoReplica(this.registro)
      }).catch(error => {
        this.dataReplica = []
        console.log('error ' + error)
      }).finally(() => {
        this.loandingQR = false
      })
    },
    rec_correos_datos () {
      // this.form_solicitante.gestion_id = this.gestion_id
      this.form_solicitante.nombre = this.rec_funciones_mayuscula(this.form_solicitante.nombre)
      this.form_solicitante.correo = this.rec_funciones_minusculas(this.form_solicitante.correo)

      // this.form_cliente.gestion_id = this.gestion_id
      this.form_cliente.nombre = this.rec_funciones_mayuscula(this.form_cliente.nombre)
      this.form_vehiculo.placa = this.rec_funciones_mayuscula(this.form_vehiculo.placa)

      this.form_qr.id_usuario = this.session.id
      this.form_qr.descripcion = this.rec_funciones_mayuscula(this.form_qr.descripcion)

      // DEFINIR SI ES DUPLICADO O RADICADO O ESCALADO
      this.form_qr.subestado_id = 2
      this.form_qr.escalado_id = 2
      if (this.form_duplicado.duplicado_id === 29) {
        // DUPLICADO
        this.form_qr.subestado_id = 4
        this.form_qr.escalado_id = 4
      } else if (this.form_producto.producto_id === 4 || this.form_producto.producto_id === 1) {
        // USUARIOS - ODT
        this.form_qr.subestado_id = 2
        this.form_qr.escalado_id = 2
      } else if ((this.form_qr.tipo_id === 76 || this.form_qr.tipo_id === 77) && this.form_qr.motivo_id === 52) {
        // FRAUDE
        this.form_qr.subestado_id = 2
        this.form_qr.escalado_id = 2
      }
    },
    rec_correos_editar () {
      this.rec_correos_datos()
      this.dialogo.pregunta = false
      this.dialogo.esperar = true
      this.dialogo.objPayload = null

      const urlroute = this.$store.getters.getUrl + 'gestion_correo'
      const data = { codigo: this.registro.codigo, gestion_id: this.gestion_id, gestion: this.form_qr, solicitante: this.form_solicitante, cliente: this.form_cliente, producto: this.form_producto, duplicado: this.form_duplicado, vehiculo: this.form_vehiculo }
      axios({
        url: urlroute,
        method: 'POST',
        data: data,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        }
      }).then(response => {
        this.dialogo.objPayload = response.data
        this.registro = response.data
        this.form_qr.keyform = this.rec_funciones_key()
        this.dialogo.detalle = true
        this.rec_correos_inicializar()
        this.$refs.form.resetValidation()
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        this.dialogo.esperar = false
      })
    },
    rec_correos_inicializar () {
      this.form_qr.keyform = this.rec_funciones_key()
      this.dataReplica = []
      this.items.objSubMotivoQR = null
      this.items.objTipoQR = null
      this.items.showMarcacion = false
      this.form_solicitante.gestion_id = 0
      this.form_solicitante.tipo_id = 0
      this.form_solicitante.nombre = ''
      this.form_solicitante.telefono = ''
      this.form_solicitante.correo = ''

      this.form_cliente.gestion_id = 0
      this.form_cliente.tipo_id = 0
      this.form_cliente.documento_id = 0
      this.form_cliente.identificacion = ''
      this.form_cliente.nombre = ''
      this.form_cliente.sexo_id = null
      this.form_cliente.lgbtiq_id = null
      this.form_cliente.especial_id = null

      this.form_producto.gestion_id = 0
      this.form_producto.producto_id = 0
      this.form_producto.productodigital_id = 0
      this.form_producto.numero = ''
      this.form_producto.pais_id = 0
      this.form_producto.departamento_id = 0
      this.form_producto.municipio_id = 0
      this.form_producto.placa = ''

      this.form_qr.aplicativo_id = 3
      this.form_qr.id_usuario = null
      this.form_qr.codigo = 0
      this.form_qr.tipo_id = 0
      this.form_qr.instancia_id = 0
      this.form_qr.canal_id = null
      this.form_qr.admision_id = null
      this.form_qr.entecontrol_id = null
      this.form_qr.motivo_id = null
      this.form_qr.submotivo_id = null
      this.form_qr.quejaexpres_id = null
      this.form_qr.descripcion = ''
      this.form_qr.fecha_requerimiento = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
      this.form_qr.segundos = 0
      this.form_qr.subestado_id = 2
      this.form_qr.escalado_id = 1
      this.form_qr.fechacerrado = null
      this.form_qr.diasint = 0
      this.form_qr.diasext = 0
      this.form_qr.fechaparacerrarexterno = null
      this.form_qr.fechaparacerrarinterno = null
      this.form_qr.area = null

      this.form_duplicado.gestion_id = 0
      this.form_duplicado.duplicado_id = 0
      this.form_duplicado.duplicadomotivo_id = null
      this.form_duplicado.reincidente_id = 0
      this.form_duplicado.canaoriginol_id = null
      this.form_duplicado.marcacion_id = null

      this.form_files.items_archivos = []
    }

  }
}
